/* Base stylesheet */
/* Version 2.0 */

/* Color Theme - #D90D58 - Wine Pink, #1EB7D9 - Bright Cerulean*/

/* =============================================================*/
/* ==================== Basic configuration ====================*/
/* =============================================================*/

@import url('https://fonts.googleapis.com/css2?family=Gideon+Roman&family=Roboto&display=swap');

* {
    
    font-family:'Helvetica Neue', sans-serif;
    font-size: 18px;
    line-height: 2.05;
    text-align: justify;
    box-sizing: border-box;
}

body {
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

a {
    color: inherit;
    text-decoration: inherit;
    transition: all 0.2s ease-in-out;
}

a:hover {
    color: #D90D58
}

small {
    font-size: small;
    color: gray;
}

.italic {
    font-style:italic;
}

.gray {
    color:#aaaaaa;
}

.blackgray {
    color:rgb(71, 71, 71)
}

.accent {
    color:#D90D58;
}

.inactive *{
    display: none;
    background-color: #00000000;
}

.lang-kr {
    font-family: "Helvetica Neue";
}

/* =============================================================*/
/* ========================= Main part =========================*/
/* =============================================================*/

main {
    width: 100%;
}

footer {
    display: block;
    width: 100%;
    height: 150%;
    background-color: lightgray;
    padding: 10px;
}

.loading {
    width: 100vw;
    height: calc(100vh - 150px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(174, 174, 174);
    transition: opacity 1s ease;
    z-index: 10;
    &.fade-out {
        opacity: 0;
        visibility: hidden;
    }
}

.spinner {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: conic-gradient(from 270deg, #1EB7D9, #D90D58 25%, #1EB7D9 50%); /* 그라데이션 적용 */
    animation: spin 2s ease-in-out infinite;
    &::before {
        content: '';
        position: absolute;
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
        background-color: white; /* 중앙을 투명하게 만듦 */
        border-radius: 50%;
        z-index: 1;
      }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}